<template>
    <v-select
        :class="{'with-selected': this.$attrs.modelValue}"
        :options="optionsToSelect"
        :reduce="option => option.value"
        :inputId="id"
        @search="fetchOptions"
    >
        <template #open-indicator="{ attributes }" v-if="hideOpenIndicator">
            <span v-bind="attributes"></span>
        </template>
        <template #no-options="{ search, searching, loading }">
            По твоєму запиту нічого не знайдено. Уточни, будь ласка
        </template>
    </v-select>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { debounce } from "lodash-es";

export default {
    components: {
        vSelect
    },
    props: {
        id: {
            required: false
        },
        defaultOptions: {
            type: Array,
            default: [],
            required: false
        },
        searchRoute: {
            type: String,
            required: false
        },
        hideOpenIndicator: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            optionsToSelect: [],
            search: ''
        }
    },
    watch: {
        '$attrs.disabled': function (value) {
            if (!value && !this.defaultOptions.length) {
                this.loadOptions()
            }
        },
        '$attrs.modelValue': function (value) {
            if (! this.optionsToSelect.find(option => option.value === value)) {
                this.loadOptions(value)
            }
        },
        options () {
            this.setOptions()
        },
        searchRoute () {
            this.loadOptions()
        }
    },
    methods: {
        fetchOptions (search, loading) {
            this.search = search

            this.$emit('searchUpdated', search)

            if (this.searchRoute && search.length) {
                loading(true);

                this.getOptions(search, loading)
            }
        },
        getOptions: debounce (function (search, loading) {
            this.loadOptions(search, loading)
        }, 350),
        loadOptions (search = '', loading) {
            const route = !search && typeof this.defaultOptions === 'string'
                ? this.defaultOptions
                : this.searchRoute

            if (!route) return;

            axios.get(route, {params: {search}})
                .then((response) => {
                    this.optionsToSelect = response.data

                    if (loading) {
                        loading(false)
                    }

                    if (! this.optionsToSelect.find(option => option.value === this.$attrs.modelValue)) {
                        this.$emit('update:modelValue', null)
                    }
                })
        },
        setOptions () {
            if (Array.isArray(this.defaultOptions)) {
                this.optionsToSelect = this.defaultOptions
            } else {
                this.loadOptions()
            }
        }
    },
    mounted () {
        if (! this.$attrs.disabled) {
            this.setOptions()
        }
    }
}
</script>

<style>
:root {
    --vs-border-color: #000;
    --vs-border-width: 2px;
    --vs-border-radius: 0;

    --vs-line-height: 1.25rem;

    --vs-selected-color: #000;

    --vs-controls-color: #000;
    --vs-search-input-color: rgb(156 163 175);
    --vs-search-input-bg: rgb(255, 255, 255);

    --vs-actions-padding: 0;
    --vs-disabled-bg: rgb(243 244 246 / var(--tw-bg-opacity));

    --vs-dropdown-max-height: 250px;
    --vs-dropdown-option-color: #000;
}

.vs__dropdown-toggle {
    padding: 0.75rem 1.5rem;
    background-color: #fff;
}

.vs__search, .vs__search:focus, .vs__selected {
    margin: 0;
    border: 0;
    padding: 0;
}

.vs__selected-options {
    padding: 0;
}

.vs__selected {
    padding-right: 5px;
}

.vs__dropdown-option, .vs__no-options, .vs__selected, .vs__search, .vs__search:focus {
    font-weight: 700;
    font-size: 0.875rem;
}

.vs__spinner, .vs__spinner:after {
    width: 3em;
    height: 3em;
}

.with-selected .vs__search:not(:focus) {
    height: 0;
}

.vs__dropdown-option {
    white-space: normal;
}
</style>
